import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './assets/images/ideight_logo.png';
import stackImage from './assets/images/onboard_step1.png';
import './assets/css/App.css';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [clickedItems, setClickedItems] = useState(Array(4).fill(false));
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(''); // State to store the dynamic user ID

  useEffect(() => {
    console.log('REACT_APP_RETAIL_CORE_HOST:', process.env.REACT_APP_RETAIL_CORE_HOST);
    console.log('REACT_APP_CUSTOMERS_CREATE_ENDPOINT:', process.env.REACT_APP_CUSTOMERS_CREATE_ENDPOINT);
    console.log('REACT_APP_RETAIL_CORE_USERNAME:', process.env.REACT_APP_RETAIL_CORE_USERNAME);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentStep(1);
    setClickedItems(Array(4).fill(false));
    setSelectedActivities([]);
  };

  const handleSquareClick = (index, content) => {
    const newClickedItems = [...clickedItems];
    newClickedItems[index] = !newClickedItems[index];
    setClickedItems(newClickedItems);

    if (newClickedItems[index]) {
      setSelectedActivities([...selectedActivities, content]);
    } else {
      setSelectedActivities(selectedActivities.filter(item => item !== content));
    }
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      handleAccountCreation();
    } else if (currentStep === 2) {
      handleProfileUpdate();
    }

    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleAccountCreation = () => {
    const newUser = {
      user: {
        external_id: generateGUID(),
        external_id_type: "reg-src",
        email: email,
        first_name: firstName,
        last_name: lastName,
        opted_in: true,
        country: "USA",
        locale: "en-us",
        user_profile: {
          brand: ["vrg", "kr"],
          shopper_persona: ["Lifestyle"],
          favorite_category: ["Dresses:Cocktail & Party", "Shoes:Heels"],
          favorite_season: "Winter",
          registration_channel: "Website",
          nearest_store: "Walnut Creek, CA 94596"
        }
      }
    };

    const username = process.env.REACT_APP_RETAIL_CORE_USERNAME;
    const password = process.env.REACT_APP_RETAIL_CORE_PASSWORD;
    const auth = btoa(`${username}:${password}`);

    const apiEndpoint = process.env.REACT_APP_CUSTOMERS_CREATE_ENDPOINT.replace('{REACT_APP_RETAIL_CORE_USERNAME}', username);

    console.log('Final Account Creation API Endpoint:', `/api${apiEndpoint}`);

    axios.post(`/api${apiEndpoint}`, newUser, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${auth}`
      }
    })
    .then(response => {
      console.log('Account Creation API Response:', response.data);
      if (response.data.user && response.data.user.id) {
        setUserId(response.data.user.id); // Set the user ID from the API response
        console.log('User ID set to:', response.data.user.id);
      } else {
        console.error('User ID not found in the response');
      }
    })
    .catch(error => {
      console.error('API Request Error:', error);
    });
  };

  const handleProfileUpdate = () => {
    if (!userId) {
      console.error('User ID is not set. Cannot update profile.');
      return;
    }

    const userProfile = {
      user_profile: {
        favorite_category: selectedActivities
      }
    };

    const username = process.env.REACT_APP_RETAIL_CORE_USERNAME;
    const password = process.env.REACT_APP_RETAIL_CORE_PASSWORD;
    const auth = btoa(`${username}:${password}`);

    let apiEndpointTemplate = process.env.REACT_APP_CUSTOMERS_CUSTOM_ATTRIBUTES_ENDPOINT
      .replace('{REACT_APP_RETAIL_CORE_USERNAME}', username)
      .replace('{USER_ID}', userId);

    console.log('Final Profile Update API Endpoint:', `/api${apiEndpointTemplate}`);

    axios.put(`/api${apiEndpointTemplate}`, userProfile, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${auth}`
      }
    })
    .then(response => {
      console.log('Profile Update API Response:', response.data);
    })
    .catch(error => {
      console.error('API Request Error:', error);
    });
  };

  const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.floor(Math.random() * 16);  // Adding Math.floor to handle the integer conversion
      const v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  };


  return (
    <div className={`App ${isModalOpen ? 'modal-open' : ''}`}>
      <img src={logo} className="App-logo" alt="IDEIGHT" onClick={toggleModal} />

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">

            {currentStep === 1 && (
              <div>
                <h2>Step 1: Create Account</h2>
                <p>Please enter your information below:</p>
                <input type="text" placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <input type="text" placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
                <input type="email" placeholder="Email Address" value={email} onChange={e => setEmail(e.target.value)} />
              </div>
            )}

            {currentStep === 2 && (
              <div className="step-2">
                <div className="step-2-left">
                  <img src={stackImage} alt="Stack" className="stack-image" />
                </div>
                <div className="step-2-right">
                  <div className="grid-container">
                    {["Square1", "Square2", "Square3", "Square4"].map((content, index) => (
                      <div
                        className={`grid-item ${clickedItems[index] ? 'clicked' : ''}`}
                        key={index}
                        onClick={() => handleSquareClick(index, content)}
                      >
                        {content}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <h2>Step 3: Payment Information</h2>
                <p>Please enter your payment information below:</p>
                <input type="text" placeholder="Credit Card Number" />
                <input type="text" placeholder="Expiration Date" />
                <input type="text" placeholder="CVV" />
              </div>
            )}

            {currentStep === 4 && (
              <div>
                <h2>Thank You!</h2>
                <p>Your information has been submitted successfully.</p>
              </div>
            )}

            <div className="modal-footer">
              {currentStep < 4 ? (
                <>
                  <span className="finish-later" onClick={toggleModal}>I will finish later</span>&nbsp;&nbsp;
                  <span className="next-step" onClick={handleNextStep}>Next Step</span>
                </>
              ) : (
                <span className="finish" onClick={toggleModal}>Finish</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
